import React from 'react';
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { SmallButton } from '../../../components/Components';

const makeDialogStyles = makeStyles(() => ({
    dialogStyle : {
        // height: '85%',
    },
    dialogTitleStyle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
    },
    dialogContentStyle: {
        height: 'auto',
        width: 'auto',
        padding: '8px 8px',
    },
    dialogActionsStyle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
        justifyContent: 'space-evenly',
    },
}))

const GetCommentsDialog = props => {
    const {
        open,
        title,
        prompt,
        comments,
        onCancelClicked,
        onOkClicked,
    } = props;

    const classes = makeDialogStyles();

    const [localComments, setLocalComments] = useState('');

    useEffect(() => {
        setLocalComments(comments);
    }, [comments]);

    const handleCommentsChange = event => {
        setLocalComments(event.target.value);
    }

    const handleOkClicked = () => {
        onOkClicked(localComments);
    }

    return (
        <Dialog
            className={classes.dialogStyle}
            open={open}
            onClose={onOkClicked}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <DialogTitle className={classes.dialogTitleStyle}>
                {title || "Confirm action and review comments"}
            </DialogTitle>
            <DialogContent dividers={false} className={classes.dialogContentStyle}>
                <DialogContentText>
                    {prompt || "Edit the comments..."}
                </DialogContentText>
                <TextField 
                    autoFocus
                    fullWidth
                    margin="dense"
                    label="Comments"
                    value={localComments}
                    onChange={handleCommentsChange}
                    multiline
                    rows={8}
                    variant="outlined"/>
            </DialogContent>
            <DialogActions>
                <SmallButton onClick={handleOkClicked}>OK</SmallButton>
                <SmallButton onClick={onCancelClicked}>Cancel</SmallButton>
            </DialogActions>
        </Dialog>
    )
}
GetCommentsDialog.propTypes = {
    open : PropTypes.bool,
    title: PropTypes.string,
    prompt: PropTypes.string,
    comments: PropTypes.string,
    onOkClicked : PropTypes.func,
    onCancelClicked : PropTypes.func,
}

export default GetCommentsDialog;
