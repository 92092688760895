const openBase64NewTab = (encodedContents, filename, mimeType) => {
    var blob = base64toBlob(encodedContents, mimeType);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
    }
}
    
const base64toBlob = (encodedContents, mimeType) => {
    const sliceSize = 1024;
    const byteCharacters = atob(encodedContents);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
    
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: mimeType});
}

export {
    openBase64NewTab,
    base64toBlob,
}
