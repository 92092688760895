// return the user data from the session storage
// export const getUser = () => {
//   const userStr = sessionStorage.getItem('user');
//   if (userStr) return JSON.parse(userStr || '');
//   else return null;
// }

// return the token from the session storage
// export const getToken = () => {
//   return sessionStorage.getItem('token') || null;
// }

// remove the token and user from the session storage
// export const removeUserSession = () => {
//   sessionStorage.removeItem('token');
//   sessionStorage.removeItem('user');
// }

// set the token and user from the session storage
// export const setUserSession = (token, user) => {
//   sessionStorage.setItem('token', token);
//   sessionStorage.setItem('user', JSON.stringify(user));
// }

// set the token and user from the session storage
// export const storeSessionId = (token) => {
//   sessionStorage.setItem('token', token);
// }

// set the token and user from the session storage
// export const storeUser = (user) => {
//   user.isSubmitter = (user.person_type_code === 'I');
//   user.isReviewer = (user.person_type_code === 'E' || user.person_type_code === 'N');
//   sessionStorage.setItem('user', JSON.stringify(user));
// }

export const getLocalUsername = () => {
  return localStorage.getItem('username');
}
export const setLocalUsername = (username) => {
  localStorage.setItem('username', username);
}
export const clearLocalUsername  = () => {
  localStorage.removeItem('username');
}

export const getLocalSessionid = () => {
  return localStorage.getItem('sessionid');
}
export const setLocalSessionid = (sessionid) => {
  localStorage.setItem('sessionid', sessionid);
}
export const clearLocalSessionid  = () => {
  localStorage.removeItem('sessionid');
}

export const getSessionToken = () => {
  return localStorage.getItem('token');
}
export const setSessionToken = (token) => {
  localStorage.setItem('token', token);
}
export const clearSessionToken  = () => {
  localStorage.removeItem('token');
}

export const getRememberMe = () => {
  var saveusername = localStorage.getItem('saveusername');
  if (saveusername == undefined)
    saveusername = "false";
  return saveusername === "true";
}
export const setRememberMe = (saveusername) => {
  if (saveusername == null)
    localStorage.removeItem('saveusername');
  else
    localStorage.setItem('saveusername', saveusername);
}
