import ApiBase from "./apiBase";

const apiBase = new ApiBase();

export default function ApiAuthentication() {
    const userLogin = async (username, password) => {
        const data = {
            username: username,
            password: password
        }
        return apiBase.doPost("authenticate", data)
        .then(response => {
            if (response) 
                return response.jwttoken;
            else
            return Promise.reject("Token not available on response");
        })
        .catch(error => {
            console.log(`authenticate returned an error: ${error}`);
            return Promise.reject(error);
        });
    }

    return {
        userLogin : userLogin,
    }
}
