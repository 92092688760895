import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';
import ReviewFilter from './components/ReviewFilter';
// import ReviewTable from './components/ReviewTable';
import ReviewService from './services/ReviewService';
import moment from 'moment';
import ReviewItemDtlTable from './components/ReviewItemDtlTable';
import ReviewCommentsViewer from './components/ReviewCommentsViewer';
import ReviewAttachmentDialog from './components/ReviewAttachmentDialog';
import ReviewDialog from './components/ReviewDialog';
import { authenticationService } from '../../services/authenticationService';
import ReviewDatagrid from './components/ReviewDatagrid';

const reviewService = new ReviewService();

const makeReviewStyles = makeStyles(() => ({
    loading: {
        height: '70%',
        width: '98%',
        position: 'fixed',
        display: 'flex',
        zIndex: '99',
    },
    paperBackground: {
        height: '100%',
        width: '100%',
        padding: '2px',
    },
    gridOuter: {
        height: '100%',
        width: '100%',
    },
    gridInnerTop: {
        height: '340px',
    },
    gridInnerBottom: {
        height: 'calc(100% - 340px)',
    },
    gridItem: {
        height: 'calc(100% - 38px)',
    },
    gridDetailTop: {
        height: '100%',
    },
    commentsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
    },
    commentsToggleLegend: {
        flex: '0 1 auto',
        paddingRight: '10px',
    },
    commentsToggleOption: {
        flex: '0 1 auto',
    },
    commentsGridItem: {
        overflow: 'scroll',
        height: 'calc(100% - 38px)',
    },
    dialogStyle: {
        height: '95%',
    },
  }));

const ReviewModule = () => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [filter, setFilter] = useState(null);
    const [reviewList, setReviewList] = useState([]);
    const [filteredReviewList, setFilteredReviewList] = useState([]);
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const [selectedListItem, setSelectedListItem] = useState(null);
    const [reviewItem, setReviewItem] = useState(null);
    const [reviewDetailItem, setReviewDetailItem] = useState(null);
    const [commentsToggle, setCommentsToggle] = useState('E');
    const [reviewDetailAttachments, setReviewDetailAttachments] = useState(null);
    const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);
    const [showReviewDialog, setShowReviewDialog] = useState(false);
    const [compliancerules, setCompliancerules] = useState(null);
    const [detailApprovals, setDetailApprovals] = useState(null);

    const classes = makeReviewStyles();

    useEffect(() => {
        // What do we do on initial module load?
    }, []);

    // on filter change
    useEffect(() => {
        if (!filter)
            return;

        setReviewList(null);
        setSelectedListItem(null);
        getReviewList(filter)
    }, [filter]);

    // When a review is selected in the list...
    useEffect(() => {
        setReviewItem(null);
        setCompliancerules(null);
        setDetailApprovals(null);
        getReviewDetails();
    }, [selectedListItem]);

    // When a review item is set
    useEffect(() => {
        setReviewDetailItem(null);
        if (showReviewDialog) {
            getCompliancerules(reviewItem);
            getDetailApprovals(reviewItem);
        }
    }, [reviewItem]);

    useEffect(() => {
        var filteredList = [];
        if (!reviewList) {
            filteredList = [];
        } else if (!searchFilterValue) {
            // Deep copy
            filteredList = JSON.parse(JSON.stringify(reviewList));
        } else if (searchFilterValue) {
            filteredList = reviewList.filter(item => {
                return (
                    (item.issuerName || '').toLowerCase().includes(searchFilterValue.toLowerCase()) ||
                    (item.jobCode || '').toLowerCase().includes(searchFilterValue.toLowerCase()) ||
                    (item.filename || '').toLowerCase().includes(searchFilterValue.toLowerCase()) ||
                    (item.description || '').toLowerCase().includes(searchFilterValue.toLowerCase()) ||
                    (item.statusDesc || '').toLowerCase().includes(searchFilterValue.toLowerCase())
                )
            });
        }
        setFilteredReviewList(filteredList);
    }, [reviewList, searchFilterValue]);

    // data access
    const getReviewList = filter => {
        setLoading(true);

        reviewService.getReviewList(filter)
        .then(result => {
            if (!Array.isArray(result)) {
                result = [];
            }
            result.sort((i1, i2) => moment(i2.submitDate).diff(moment(i1.submitDate)));
            setReviewList(result);
            setLoading(false);
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            setLoading(false);
        });
    }

    const getReviewDetails = async () => {
        if (!selectedListItem || !selectedListItem.issuerArtId)
            return;
        
        setLoading(true);

        reviewService.getReviewItem(selectedListItem.issuerArtId)
        .then(response => {
            setReviewItem(response);
            setLoading(false);
        })
        .catch(error => {
            Promise.reject(`An error occurred retrieving the review details: ${error}`);
            setLoading(false);
        });
    }

    const getCompliancerules = () => {
        if (!reviewItem) {
            return;
        }
        setLoading(true);

        var details = reviewItem.reviewItemDetails;
        details.sort((item1, item2) => {return item1.version > item2.version? -1: 1});
        var latestDetail = details[0];
        reviewService.getCompliancerules(latestDetail.issuerArtDtlId)
        .then(result => {
            if (!result || result == "")
                result = "No Exceptions Noted";
            setCompliancerules(result);
            setLoading(false);
        })
        .catch(error => {
            console.log(`An error occurred getting the compliance rules: ${error}`);
            setLoading(false);
        });
    }

    const getDetailApprovals = () => {
        if (!reviewItem) {
            return;
        }
        setLoading(true);

        var details = reviewItem.reviewItemDetails;
        details.sort((item1, item2) => {return item1.version > item2.version? -1: 1});
        var latestDetail = details[0];
        reviewService.getDetailApprovals(latestDetail.issuerArtDtlId)
        .then(result => {
            var mePersonId = authenticationService.getCurrentuser().personId;
            var sortedResult = result.sort((item1, item2) => {
                if (item1.personId == mePersonId) {
                    return -1;
                }
                if (item2.personId == mePersonId) {
                    return 1;
                }
                if (item1.personName < item2.personName) {
                    return -1;
                }
                return 1;
            })

            latestDetail.detailApprovals = sortedResult;

            setDetailApprovals(sortedResult);
            setLoading(false);
        })
        .catch(error => {
            console.log(`An error occurred getting the approvals: ${error}`);
            setLoading(false);
        });
    }

    const updateReviewItem = rev => {
        if (!rev)
            return;

        setReviewItem(rev);
    }
    const updateReviewItemDetails = details => {
        if (!details)
            return;

        var revItem = reviewItem;
        revItem.reviewItemDetails = details;
        updateReviewItem(revItem);
    }
    const updateReviewItemDetail = detail => {
        if (!detail)
            return;

        var details = reviewItem.reviewItemDetails;
        var dtlIx = details.findIndex(item => item.issuerArtDtlId == detail.issuerArtDtlId);
        if (dtlIx >= 0) {
            details[dtlIx] = detail;
        } else {
            details.push(detail);
        }
        updateReviewItemDetails(details);
    }
    const updateReviewItemDetailApproval = app => {
        if (!app)
            return;

        var rev = reviewItem;
        var appDtl = rev.reviewItemDetails.find(item => item.issuerArtDtlId == app.issuerArtDtlId);
        if (!appDtl)
            return;
        
        var apps = appDtl.detailApprovals;
        var appIx = apps.findIndex(item => item.issuerArtDtlApprovalId = app.issuerArtDtlApprovalId);
        if (appIx >= 0) {
            apps[appIx] = app;
        } else {
            apps.push(app);
        }
        appDtl.detailApprovals = apps;
        updateReviewItemDetail(appDtl);
    }

    // event handlers
    const handleFilterChange = rowItem => {
        // setSearchFilterValue('');
        setFilter(rowItem);
    };
    const handleSearchFilterChange = searchvalue => {
        console.log(`searching for ${searchvalue}`);
        setSearchFilterValue(searchvalue);
    }

    const handleReviewListSelection = item => {
        setSelectedListItem(item);
    }

    const handleReviewDtlSelected = detail => {
        setReviewDetailItem(detail);
    }

    const handleShowReviewDialog = reviewToShow => {
        if (!reviewToShow) {
            return;
        }
        setShowReviewDialog(true);
        if (!selectedListItem || reviewToShow.issuerArtId != selectedListItem.issuerArtId) {
            setSelectedListItem(reviewToShow);
        } else {
            getCompliancerules();
            getDetailApprovals();
        }
    }

    const handleShowAttachments = detail => {
        handleReviewDtlSelected(detail);
        setReviewDetailAttachments(reviewDetailItem.detailAttachments);
        setShowAttachmentsDialog(true);
    }
    const handleAttachmentsChanged = changedAttachments => {
        // Update the attachments for the detail
        reviewDetailItem.reviewDetailAttachments = changedAttachments;
        // update the detail for the review
        // save the review
    }
    const handleCloseAttachments = () => {
        handleSaveReview()
        .then(setShowAttachmentsDialog(false));
    }

    const handleCommentsToggleChange = event => {
        setCommentsToggle(event.target.value)
    }

    const removeAttachmentFromList = attachment => {
        var detail = reviewDetailItem;
        var attachments = detail.detailAttachments;
        var deletedIx = attachments.findIndex(att => att.issuerArtDtlAttachmentId == attachment.issuerArtDtlAttachmentId);
        if (deletedIx >= 0) {
            attachments.splice(deletedIx, 1);
            detail.detailAttachments = attachments;
            var rev = reviewItem;
            var dtlIx = reviewItem.reviewItemDetails.findIndex(dtl => dtl.issuerArtDtlId == detail.issuerArtDtlId);
            if (dtlIx >= 0) {
                rev.reviewItemDetails[dtlIx] = detail;
                setReviewItem(rev);
                setReviewDetailItem(detail);
                setReviewDetailAttachments(detail.detailAttachments);
            }
        }
        handleShowAttachments(detail);
    }

    const handleRemoveAttachment = attachment => {
        if (!attachment)
            return null;
        
        if (attachment.issuerArtDtlAttachmentId <= 0)
            removeAttachmentFromList(attachment);
        
        reviewService.removeAttachmentById(attachment.issuerArtDtlAttachmentId)
        .then(() => {
            removeAttachmentFromList(attachment);
        })
        .catch(error => {
            console.log(`An error occurred removing the attachment: ${error}`);
            Promise.reject(`An error occurred removing the attachment: ${error}`);
        })
    }

    const updateReviewItemInList = rev => {
        var list = reviewList;
        var index = list.findIndex(item => item.issuerArtId == rev.issuerArtId);
        if (!index == -1) {
            index = list.find(item => item.issuerArtId <= 0);
        }
        if (index >= 0) {
            list[index] = Object.assign(list[index], rev);
        } else {
            list.push(rev);
        }

        setReviewList(list);
    }

    const handleSaveReview = async () => {
        if (!reviewItem) {
            return;
        }
        setSaving(true);
        reviewService.saveReview(reviewItem)
        .then(response => {
            setSaving(false);
            if (!response)
                return;

            setReviewItem(response);
            var listItem = {
                issuerArtId : response.issuerArtId,
                issuerName : response.issuerName,
                submittedByPersonName : response.submittedByPersonName,
                filename : response.filename,
                keywords : response.keywords,
                description : response.description,
                status : response.statusDesc,
                currentVersion : response.currentVersion,
                uploadDate : response.uploadDate,
                jobCode : response.jobCode,
                submissionTarget : response.submissionTarget,
                dtlStatus : response.dtlStatusDesc,
            }
            
            updateReviewItemInList(listItem);
        })
        .catch(error => {
            console.log(`An error occurred saving the review item: ${error}`);
        })
    }

    const handleCloseReviewDialog = () => {
        handleSaveReview(reviewItem);
        setShowReviewDialog(false);
    }

    const handleReviewPend = dtl => {
        setSaving(true);
        handleSaveReview(reviewItem)
        .then(() => {
            setSaving(false);
            return reviewService.pendReview(dtl)
        })
        .then(() => {
            setShowReviewDialog(false);
            setLoading(true);
            return reviewService.getReviewList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setReviewList([]);
                setLoading(false);
                return;
            }
            setReviewList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleReviewApprove = dtl => {
        setSaving(true);
        handleSaveReview(reviewItem)
        .then(() => {
            setSaving(false);
            return reviewService.approveReview(dtl)
        })
        .then(() => {
            setShowReviewDialog(false);
            setLoading(true);
            return reviewService.getReviewList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setReviewList([]);
                setLoading(false);
                return;
            }
            setReviewList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleReviewReject = dtl => {
        setSaving(true);
        handleSaveReview(reviewItem)
        .then(() => {
            setSaving(false);
            return reviewService.returnReview(dtl)
        })
        .then(() => {
            setShowReviewDialog(false);
            setLoading(true);
            return reviewService.getReviewList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setReviewList([]);
                setLoading(false);
                return;
            }
            setReviewList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleReviewFinalApprove = dtl => {
        setSaving(true);
        handleSaveReview(reviewItem)
        .then(() => {
            setSaving(false);
            return reviewService.finalApproveReview(dtl)
        })
        .then(() => {
            setShowReviewDialog(false);
            setLoading(true);
            return reviewService.getReviewList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setReviewList([]);
                setLoading(false);
                return;
            }
            setReviewList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleReviewFinalReject = dtl => {
        setSaving(true);
        handleSaveReview(reviewItem)
        .then(() => {
            setSaving(false);
            return reviewService.finalReturnReview(dtl)
        })
        .then(() => {
            setShowReviewDialog(false);
            setLoading(true);
            return reviewService.getReviewList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setReviewList([]);
                setLoading(false);
                return;
            }
            setReviewList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    return (
        <Fragment>
            {loading && 
                <Grid container direction="column" justify="center" alignItems="center" className={classes.loading}>
                    <CircularProgress size="76px" />
                    <span>Loading...</span>
                </Grid>
            }
            {saving && 
                <Grid container direction="column" justify="center" alignItems="center" className={classes.loading}>
                    <CircularProgress size="76px" />
                    <span>Saving...</span>
                </Grid>
            }

            {showAttachmentsDialog && reviewDetailItem && 
                <ReviewAttachmentDialog
                    open={showAttachmentsDialog}
                    review={reviewItem}
                    reviewDetail={reviewDetailItem}
                    dtlAttachments={reviewDetailAttachments}
                    source="review"
                    onDtlAttachmentsChanged={handleAttachmentsChanged}
                    onRemoveAttachment={handleRemoveAttachment}
                    onClose={handleCloseAttachments}/>
            }

            {showReviewDialog && reviewItem && 
                <ReviewDialog
                    open={showReviewDialog}
                    reviewItem={reviewItem}
                    compliancerules={compliancerules}
                    detailApprovals={detailApprovals}
                    onUpdateApproval={updateReviewItemDetailApproval}
                    onUpdateDetail={updateReviewItemDetail}
                    onClose={handleCloseReviewDialog}
                    onShowAttachments={handleShowAttachments}
                    onPend={handleReviewPend}
                    onApprove={handleReviewApprove}
                    onReject={handleReviewReject}
                    onFinalApprove={handleReviewFinalApprove}
                    onFinalReject={handleReviewFinalReject}
                    />
            }
            
            <Grid container spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                className={classes.gridOuter}>
                <Grid item xs={12} className={classes.gridInnerTop}>
                    <Paper className={classes.paperBackground}>
                        <Grid container spacing={0} direction="row" justify="flex-start" alignItems="stretch" className={classes.gridDetailTop}>
                            <Grid item xs={12} container direction="row" alignItems="baseline">
                                <ReviewFilter onFilterChange={handleFilterChange}
                                    searchFilterValue={searchFilterValue}
                                    onSearchFilterChange={handleSearchFilterChange}/>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <ReviewDatagrid
                                    reviewItems={filteredReviewList}
                                    onSelection={handleReviewListSelection}
                                    onShowReview={handleShowReviewDialog}/>
                                {/* <ReviewTable
                                    reviewItems={filteredReviewList}
                                    selectedListItem={selectedListItem}
                                    onSelection={handleReviewListSelection}
                                    onShowReview={handleShowReviewDialog}/> */}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={4} className={classes.gridInnerBottom}>
                    <Paper className={classes.paperBackground}>
                        <Grid container spacing={0} direction="row" justify="flex-start" alignItems="stretch" className={classes.gridDetailTop}>
                            <Grid item xs={12}>
                                <Typography align="center">History</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <ReviewItemDtlTable
                                    reviewItem={reviewItem}
                                    selectedListItem={reviewDetailItem}
                                    onSelection={handleReviewDtlSelected}
                                    onShowAttachments={handleShowAttachments}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={8} className={classes.gridInnerBottom}>
                    <Paper className={classes.paperBackground}>
                        <Grid container spacing={0} direction="row" justify="flex-start" alignItems="stretch" className={classes.gridDetailTop}>
                            <Grid item xs={12}>
                                <FormControl className={classes.commentsContainer} size="small">
                                    <FormLabel className={classes.commentsToggleLegend} >Reviewer Comments:</FormLabel>
                                    <RadioGroup row name="commentsToggleGroup" value={commentsToggle} onChange={handleCommentsToggleChange}>
                                        <FormControlLabel 
                                            className={classes.commentsToggleOption} 
                                            value="E"
                                            control={<Radio size="small" />} 
                                            label="To Client"/>
                                        <FormControlLabel 
                                            className={classes.commentsToggleOption} 
                                            value="I"
                                            control={<Radio size="small" />} 
                                            label="Internal"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} container direction="row" className={classes.commentsGridItem}>
                                <ReviewCommentsViewer reviewItem={reviewItem} 
                                    commentsToggle={commentsToggle}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ReviewModule;
