import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import FileApi from '../../../services/api/FileApi';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { openBase64NewTab } from '../../../utils/FileUtils';
import { authenticationService } from '../../../services/authenticationService';
import GetCommentsDialog from './GetCommentsDialog';

const fileApi = new FileApi();

const makeDialogStyles = makeStyles(() => ({
    dialogStyle : {
        // height: '85%',
    },
    dialogTitleStyle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
    },
    dialogContentStyle: {
        height: 'auto',
        width: 'auto',
        padding: '8px 8px',
    },
    dialogActionsStyle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
        justifyContent: 'space-evenly',
    },
    contentGrid: {

    },
    iframeContainer : {
        height: '530px',
        // minHeight : '500px',
        // maxHeight : '500px',
    },
    iframeElement : {
        height : '100%',
        width: '100%',
    },
    imageElement : {
        width: '100%',
    },
    textElement : {
        height : '100%',
        textAlign: 'center',
    },
    imagePaperStyle: {
        height: '100%',
        width: '100%',
        padding: '3px',
    },
    imageGridItem: {
        height: '100%',
    },
    iconButton: {
        padding : '4px',
    },
    leftBodyContainer: {
        height: 'calc(100% - 36px)',
        maxHeight: 'calc(100% - 36px)',
    },
    approversPaperStyle: {
        height: 'calc(65% - 36px)',
    },
    compliancerulesPaperStyle: {
        paddingTop: '10px',
        height: '50%',
    },
    approversContentStyle: {
        height: '100%',
    },
    complianceRulesContentStyle: {
        height: '100%',
    },
    tableContainerStyle: {
        height: '100%',
    },
    tableHeadStyle: {
        padding: '0px 5px',
    },
    tableHeadRowStyle: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailRowEven: {
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    reviewerCol: {
    },
    statusCol: {
        maxWidth: '125px',
    },
}));

const SmallButton = props => {
    return (
        <Button size="small" variant="contained" {...props}/>
    )
}

const createFileResourceDto = attachmentItem => {
    if (!attachmentItem || 
        !attachmentItem.filesize || 
        !attachmentItem.filename || 
        !attachmentItem.localFilename || 
        !attachmentItem.localPath)
        return null;

    const fileResourceDto = {
        originalFilename : attachmentItem.filename,
        localFilename : attachmentItem.localFilename,
        localFilepath : attachmentItem.localPath,
        filesize : attachmentItem.filesize,
        mimeType : "",
        encodedFile : "",
    }
    return fileResourceDto;
}

const ReviewDialog = props => {
    const {
        open,
        reviewItem,
        compliancerules,
        detailApprovals,
        onUpdateApproval,
        onUpdateDetail,
        onClose,
        onShowAttachments,
        onPend,
        onApprove,
        onReject,
        onFinalApprove,
        onFinalReject,
    } = props;

    const [reviewDetailItem, setReviewDetailItem] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [attachmentContent, setAttachmentContent] = useState(null);
    const [attachmentMimeType, setAttachmentMimeType] = useState(null);
    const [attachmentObjectUrl, setAttachmentObjectUrl] = useState(null);
    const [selectedApproval, setSelectedApproval] = useState(null);
    const [showApprovers, setShowApprovers] = useState(false);
    const [largeContent, setLargeContent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [intComments, setIntComments] = useState("");
    const [extComments, setExtComments] = useState("");
    const [mePersonId, setMePersonId] = useState(null);
    const [plasticsRecdInd, setPlasticsRecdInd] = useState(false);
    const [showCommentsDialog, setShowCommentsDialog] = useState(false);
    const [commentsDialogTitle, setCommentsDialogTitle] = useState(null);
    const [commentsDialogPrompt, setCommentsDialogPrompt] = useState(null);
    const [commentsDialogComments, setCommentsDialogComments] = useState(null);
    const [commentsDialogOkHandler, setCommentsDialogOkHandler] = useState(null);

    const classes = makeDialogStyles();

    useEffect(() => {
        // What do we do on initial module load?
        setMePersonId(authenticationService.getCurrentuser().personId);
        return function releaseObjectUrl() {
            if (attachmentObjectUrl) {
                URL.revokeObjectURL(attachmentObjectUrl);
            }
        }
    }, []);

    useEffect(() => {
        var details = reviewItem.reviewItemDetails;
        details.sort((item1, item2) => {return item1.version > item2.version? -1: 1});
        setReviewDetailItem(details[0]);
    }, [reviewItem])

    useEffect(() => {
        if (!reviewDetailItem) {
            setAttachments(null);
            setSelectedAttachment(null);
            return;
        }
        setPlasticsRecdInd(reviewDetailItem.plasticReceivedInd);
        setAttachments(reviewDetailItem.detailAttachments.filter(item => item.attachmentType == 'SUBMISSION'));
    }, [reviewDetailItem])

    useEffect(() => {
        if (attachments && attachments.length > 0) {
            setSelectedAttachment(attachments[0]);
        } else {
            setSelectedAttachment(null);
        }
    }, [attachments])

    useEffect(() => {
        setAttachmentContent(null);
        URL.revokeObjectURL(attachmentObjectUrl);
        setAttachmentObjectUrl(null);
        if (selectedAttachment && !!selectedAttachment.localFilename)
            getAttachmentContent(false);
        else
            getAttachmentContent(null);
    }, [selectedAttachment]);

    useEffect(() => {
        if (!detailApprovals) {
            return;
        }
        if (selectedApproval) {
            var selIx = detailApprovals.findIndex(item => item.issuerArtDtlApprovalId == selectedApproval.issuerArtDtlApprovalId);
            if (selIx >= 0) {
                setSelectedApproval(detailApprovals[selIx]);
            }
        } else {
            var meIx = detailApprovals.findIndex(item => item.personId == mePersonId);
            if (meIx >= 0) {
                setSelectedApproval(detailApprovals[meIx]);
            }
        }
    }, [detailApprovals])

    useEffect(() => {
        if (!selectedApproval) {
            setIntComments('');
            setExtComments('');
            return;
        }
        setIntComments(selectedApproval.intComments || '');
        setExtComments(selectedApproval.extComments || '');
    }, [selectedApproval])

    const getAttachmentContent = fetchLargeFiles => {
        if (!selectedAttachment)
            return;

        if (selectedAttachment.filesize >= 10000000 && !fetchLargeFiles) {
            setLargeContent(true);
            return;
        } else {
            setLargeContent(false);
        }

        setLoading(true);

        Promise.all([
            fileApi.getFileEncoded(createFileResourceDto(selectedAttachment)),
            fileApi.getFileBinary(createFileResourceDto(selectedAttachment))
        ])
        .then(([
            encodedResponse,
            binaryResponse
        ]) => {
            if (encodedResponse) {
                setAttachmentContent(encodedResponse.encodedFile);
                setAttachmentMimeType(encodedResponse.mimeType);
            }
            if (binaryResponse) {
                setAttachmentObjectUrl(URL.createObjectURL(binaryResponse));
            }
            setLoading(false);
        })
        .catch(error => {
            console.log(`An error occurred retrieving the file contents for display: ${error}`);
            setLoading(false);
        });
    }

    const onDownloadClicked = () => {
        if (!selectedAttachment) {
            console.log("No attachment selected to download.");
            return;
        }

        const fileDto = createFileResourceDto(selectedAttachment);
        if (!fileDto) {
            console.log("Could not create file resource request");
            return;
        }

        fileApi.downloadFile(fileDto)
        .then(response => {
            let blob = new Blob([response], {type: attachmentMimeType});
    
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileDto.originalFilename;
            a.click();
        })
    }

    const onPopoutClicked = () => {
        if (!attachmentContent)
            return;

        openBase64NewTab(attachmentContent, selectedAttachment.filename, attachmentMimeType);
    }

    const handlePlasticsReceivedChanged = event => {
        var dtlItem = reviewDetailItem;
        setPlasticsRecdInd(event.target.checked);
        dtlItem.plasticReceivedInd = event.target.checked;
        setReviewDetailItem(dtlItem);
        onUpdateDetail(dtlItem);
    }

    const handleIntCommentsChanged = event => {
        setIntComments(event.target.value);
    }
    const handleExtCommentsChanged = event => {
        setExtComments(event.target.value);
    }
    const handleCopyIntToExt = () => {
        setExtComments(intComments);
    }
    const handleCopyExtToInt = () => {
        setIntComments(extComments);
    }
    const handleCommitIntCommentChanges = () => {
        var app = selectedApproval;
        app.intComments = intComments;
        app.status = "I";
        app.statusDesc = "In process"
        onUpdateApproval(app);
    }
    const handleCommitExtCommentChanges = () => {
        var app = selectedApproval;
        app.extComments = extComments;
        app.status = "I";
        app.statusDesc = "In process"
        onUpdateApproval(app);
    }
    
    const handleShowAttachmentsClicked = () => {
        onShowAttachments(reviewDetailItem);
    }

    const handlePendClicked = () => {
        var meApproval = detailApprovals.find(item => item.personId == mePersonId);
        meApproval.status = 'P';
        meApproval.statusDate = new Date();
        onUpdateApproval(meApproval);
        onPend(reviewDetailItem);
    }

    const handleApproveClicked = () => {
        var meApproval = detailApprovals.find(item => item.personId == mePersonId);
        meApproval.status = 'A';
        meApproval.statusDate = new Date();
        onUpdateApproval(meApproval);
        onApprove(reviewDetailItem);
    }

    const handleReturnClicked = () => {
        var meApproval = detailApprovals.find(item => item.personId == mePersonId);
        meApproval.status = 'R';
        meApproval.statusDate = new Date();
        onUpdateApproval(meApproval);
        onReject(reviewDetailItem);
    }

    const getApprovalComments = detailItem => {
        // Get approval comments
        var approvals = [];
        var reviewerComments = "Version " + detailItem.version;
        if (detailItem && detailItem.detailApprovals) {
            approvals = detailItem.detailApprovals;
        }
        approvals.forEach(approval => {
            reviewerComments += "\n";
            reviewerComments += " (Discover) (" + approval.statusDesc + "):";
            reviewerComments += "\n";
            reviewerComments += approval.extComments || "No Comments Entered";
            reviewerComments += "\n";
        });

        return reviewerComments || '';
    }

    const handleFinalApproveClicked = () => {
        setCommentsDialogTitle("Confirm Final Approval and review comments");
        setCommentsDialogPrompt("Edit the approval comments");
        setCommentsDialogOkHandler(() => handleFinalApproveCommentsOk);
        setCommentsDialogComments(getApprovalComments(reviewDetailItem));
        setShowCommentsDialog(true);
    }
    const handleFinalApproveCommentsOk = comments => {
        reviewDetailItem.appReviewerComments = comments;
        var meApproval = detailApprovals.find(item => item.personId == mePersonId);
        meApproval.status = 'A';
        meApproval.statusDate = new Date();
        onUpdateApproval(meApproval);
        onFinalApprove(reviewDetailItem);
        handleCloseCommentsDialog();
    }

    const handleFinalReturnClicked = () => {
        setCommentsDialogTitle("Confirm Final Return and review comments");
        setCommentsDialogPrompt("Edit the comments");
        setCommentsDialogOkHandler(() => handleFinalReturnCommentsOk);
        setCommentsDialogComments(getApprovalComments(reviewDetailItem));
        setShowCommentsDialog(true);
    }
    const handleFinalReturnCommentsOk = comments => {
        reviewDetailItem.appReviewerComments = comments;
        var meApproval = detailApprovals.find(item => item.personId == mePersonId);
        meApproval.status = 'R';
        meApproval.statusDate = new Date();
        onUpdateApproval(meApproval);
        onFinalReject(reviewDetailItem);
        handleCloseCommentsDialog();
    }

    const handleImageApproverToggleClicked = () => {
        setShowApprovers(!showApprovers);
    }

    const handleCloseCommentsDialog = () => {
        setShowCommentsDialog(false);
        setCommentsDialogTitle('');
        setCommentsDialogPrompt("");
        setCommentsDialogOkHandler(null);
        setCommentsDialogComments('');
    }

    return (
        <Fragment>
            {showCommentsDialog && 
                <GetCommentsDialog
                    open={showCommentsDialog}
                    title={commentsDialogTitle}
                    prompt={commentsDialogPrompt}
                    comments={commentsDialogComments}
                    onOkClicked={commentsDialogOkHandler}
                    onCancelClicked={handleCloseCommentsDialog}/>
            }

            {reviewItem && 
                <Dialog
                    className={classes.dialogStyle}
                    fullWidth
                    maxWidth="lg"
                    open={open}
                    onClose={onClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                    
                    <DialogTitle className={classes.dialogTitleStyle}>
                        Reviewing job {reviewItem.jobCode} {reviewItem.filename} version {reviewDetailItem && reviewDetailItem.version}
                    </DialogTitle>

                    <DialogContent dividers={false} className={classes.dialogContentStyle}>
                        <Grid container direction="row" justify="space-between" alignItems="stretch" className={classes.contentGrid}>
                            {reviewItem.submissionTarget == "DN" &&
                                <Grid item xs={12} container direction="row">
                                    <Typography>Art Type: {reviewItem.artTypeDescription || "Unknown"}</Typography>
                                </Grid>
                            }
                            <Grid item xs={7}  
                                container direction="row" alignItems="stretch" spacing={1} 
                                className={classes.iframeContainer}>
                                {loading && 
                                    <Grid item container direction="column" justify="center" alignItems="center">
                                        <CircularProgress size="75px" />
                                        <span>Loading...</span>
                                    </Grid>
                                }
                                <Grid item xs={12} 
                                    container direction="row"
                                    className={classes.leftBodyContainer}>
                                    {!showApprovers && 
                                        <Paper className={classes.imagePaperStyle}>
                                            {largeContent && 
                                                <div>
                                                    <span>This file is very large and may take awhile to display</span>
                                                    <Button size="small" variant="contained" onClick={() => {getAttachmentContent(true)}}>Show file anyway</Button>
                                                </div>}
                                            {attachmentContent &&
                                                <Grid item container direction="column" justify="center" alignItems="stretch" className={classes.imageGridItem}>
                                                    {attachmentMimeType && attachmentMimeType == "application/pdf" && attachmentObjectUrl && 
                                                        <iframe src={attachmentObjectUrl} 
                                                            className={classes.iframeElement} 
                                                            type="application/pdf"/>}
                                                    {attachmentMimeType && attachmentMimeType.startsWith("image") && <img className={classes.imageElement} src={"data:" + attachmentMimeType + ";base64, " + attachmentContent}/>}
                                                    {(!attachmentMimeType || 
                                                        (attachmentMimeType && attachmentMimeType != "application/pdf" && !attachmentMimeType.startsWith("image")))
                                                        && <Typography className={classes.textElement}>The attachment [{selectedAttachment.filename}] cannot be displayed.</Typography>}
                                                </Grid>
                                            }
                                        </Paper>
                                    }
                                    {showApprovers && 
                                        <Grid item xs={12} 
                                            container direction="row" alignItems="stretch" spacing={2}
                                            className={classes.imagePaperStyle}>
                                            <Grid item xs={12} className={classes.approversPaperStyle}>
                                                <Paper className={classes.imagePaperStyle}>
                                                    <TableContainer className={classes.tableContainerStyle}>
                                                        <Table stickyHeader size="small">
                                                            <TableHead className={classes.tableHeadStyle}>
                                                                <TableRow className={classes.tableHeadRowStyle}>
                                                                    <TableCell className={`${classes.tableHeadCell} ${classes.reviewerCol}`}>
                                                                        Reviewer
                                                                    </TableCell>
                                                                    <TableCell className={`${classes.tableHeadCell} ${classes.statusCol}`}>
                                                                        Status
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {detailApprovals && detailApprovals.map((item, index) => {
                                                                    const onRowClick = () => {
                                                                        setSelectedApproval(item);
                                                                    }
                                                                    return (
                                                                        <TableRow key={`${item.issuerArtDtlApprovalId}.${index}`} hover={true} onClick={onRowClick} selected={selectedApproval && selectedApproval.issuerArtDtlApprovalId == item.issuerArtDtlApprovalId}>
                                                                            <TableCell className={`${classes.tableDetailCell} ${classes.reviewerCol}`}>
                                                                                {item.personName}
                                                                            </TableCell>
                                                                            <TableCell className={`${classes.tableDetailCell} ${classes.statusCol}`}>
                                                                                {item.statusDesc}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} className={classes.compliancerulesPaperStyle}>
                                                <TextField fullWidth multiline rows={10} label="Compliance Rule Exceptions" value={compliancerules} disabled={true} size="small" variant="outlined" className={classes.complianceRulesContentStyle}/>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12} container direction="row">
                                    <Grid item xs={6}>
                                        <SmallButton onClick={handleImageApproverToggleClicked}>
                                            {showApprovers? "Attachment": "Reviewers"}
                                        </SmallButton>
                                    </Grid>
                                    <Grid item xs={6} container direction="row" justify="flex-end">
                                        <Tooltip title="Download file">
                                            <IconButton onClick={onDownloadClicked} className={classes.iconButton}>
                                                <SaveAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Show in new window">
                                            <IconButton onClick={onPopoutClicked} className={classes.iconButton}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} 
                                container direction="row" alignItems="stretch" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth multiline rows={11} label="Comments to Client" size="small" variant="outlined" 
                                        value={extComments} onChange={handleExtCommentsChanged} onBlur={handleCommitExtCommentChanges}
                                        disabled={!selectedApproval || selectedApproval.personId != mePersonId}/>
                                </Grid>
                                <Grid item xs={12} container direction="row" justify="space-around">
                                    <Grid item >
                                        <SmallButton startIcon={<ArrowDownwardIcon/>} onClick={handleCopyExtToInt} disabled={!selectedApproval || selectedApproval.personId != mePersonId}>
                                            Copy to Internal
                                        </SmallButton>
                                    </Grid>
                                    <Grid item >
                                        <SmallButton  startIcon={<ArrowUpwardIcon/>} onClick={handleCopyIntToExt} disabled={!selectedApproval || selectedApproval.personId != mePersonId}>
                                            Copy to Client
                                        </SmallButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth multiline rows={11} label="Internal Comments" size="small" variant="outlined" 
                                        value={intComments} onChange={handleIntCommentsChanged} onBlur={handleCommitIntCommentChanges}
                                        disabled={!selectedApproval || selectedApproval.personId != mePersonId}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions className={classes.dialogActionsStyle}>
                        <Grid container direction="row" spacing={1} alignItems="baseline">
                            <Grid item xs={4} container direction="row" spacing={1} justify="flex-start" alignItems="baseline">
                                <Grid item>
                                    <Tooltip title="Show Attachments/Add Samples">
                                        <span>
                                            <SmallButton onClick={handleShowAttachmentsClicked}>Attachments</SmallButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                {reviewDetailItem && 
                                    <Grid item>
                                        <FormControlLabel label="Plastics Received"
                                            control={<Checkbox checked={plasticsRecdInd} onChange={handlePlasticsReceivedChanged} name="chkPlasticsRecvd"/>}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={7} container direction="row" spacing={1} justify="center" alignItems="baseline">
                                <Grid item>
                                    <SmallButton onClick={handlePendClicked}>Pend</SmallButton>
                                </Grid>
                                {reviewItem.submissionTarget == "DN" &&
                                    <Grid item>
                                        <SmallButton onClick={handleApproveClicked}>Approve</SmallButton>
                                    </Grid>
                                }
                                {reviewItem.submissionTarget == "DN" &&
                                    <Grid item>
                                        <SmallButton onClick={handleReturnClicked}>Return</SmallButton>
                                    </Grid>
                                }
                                <Grid item>
                                    <SmallButton onClick={handleFinalApproveClicked}>Final Approve</SmallButton>
                                </Grid>
                                <Grid item>
                                    <SmallButton onClick={handleFinalReturnClicked}>Final Return</SmallButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} container direction="row" spacing={1} justify="flex-end" alignItems="baseline">
                                <Grid item>
                                    <SmallButton onClick={onClose}>Close</SmallButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    );
}
ReviewDialog.propTypes = {
    open : PropTypes.bool,
    reviewItem : PropTypes.object,
    compliancerules: PropTypes.string,
    detailApprovals : PropTypes.array,
    onUpdateApproval : PropTypes.func,
    onUpdateDetail : PropTypes.func,
    onClose : PropTypes.func,
    onShowAttachments : PropTypes.func,
    onPend : PropTypes.func,
    onApprove : PropTypes.func,
    onReject : PropTypes.func,
    onFinalApprove : PropTypes.func,
    onFinalReject : PropTypes.func,
}

export default ReviewDialog;
