import React, { Fragment } from 'react';
// import Login from './Login';
import authenticationService from '../services/authenticationService';
import PropTypes from "prop-types";
import SignIn from './signIn';

const Home = props => {
  const {
    onLoginComplete
  } = props;

  return (
    <Fragment>
      {authenticationService.getCurrentuser() && <div>
        <div>
          Welcome, {authenticationService.getCurrentuser() ? authenticationService.getCurrentuser().firstname: "guest"}!
        </div>
      </div>}
      {!authenticationService.getCurrentuser() && <div>
        {/* <Login onLoginComplete={props.onLoginComplete}/> */}
        <SignIn onLoginComplete={onLoginComplete}/>
      </div>}
    </Fragment>
  );
}

Home.propTypes = {
  onLoginComplete: PropTypes.func.isRequired,
};

export default Home;
