import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';

const DNSubmissionForm = props => {
    const {
        submission,
        selectedDetail,
        artType,
        onArtTypeChange,
        prelaunchRecapFlag,
        onPrelaunchRecapFlagChange,
        dtlDescription,
        onDescriptionChange,
        dtlReviewerComments,
    } = props;

    return (
        <Fragment>
            <Grid item>
                <FormControl component="fieldset" variant="outlined" required={true}>
                    <FormLabel>Art Type</FormLabel>
                    <RadioGroup 
                        row={true}
                        value={artType}
                        onChange={onArtTypeChange}>
                        <FormControlLabel value="CD" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Card Design"/>
                        <FormControlLabel value="CP" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Card Proof"/>
                        <FormControlLabel value="MM" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Marketing Materials"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl component="fieldset" variant="outlined">
                    <FormLabel>Request</FormLabel>
                    <RadioGroup 
                        row={true}
                        disabled={!submission.isEditable || !selectedDetail.isEditable}
                        value={prelaunchRecapFlag}
                        onChange={onPrelaunchRecapFlagChange}>
                        <FormControlLabel value="PL" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Prelaunch"/>
                        <FormControlLabel value="QR" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Quarterly Recap"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!(selectedDetail && selectedDetail.isEditable)}
                    name="description"
                    label="Description"
                    size="small" 
                    fullWidth
                    multiline
                    rows={3}
                    value={dtlDescription || ""}
                    variant="outlined"
                    onChange={onDescriptionChange}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Reviewer Comments"
                    size="small" 
                    fullWidth
                    multiline
                    rows={3}
                    value={dtlReviewerComments || ""}
                    variant="outlined"
                    disabled={true}/>
            </Grid>
        </Fragment>
    )
}

DNSubmissionForm.propTypes = {
    submission : PropTypes.object,
    selectedDetail : PropTypes.object,
    artType : PropTypes.string,
    onArtTypeChange : PropTypes.func,
    prelaunchRecapFlag : PropTypes.string,
    onPrelaunchRecapFlagChange : PropTypes.func,
    dtlDescription : PropTypes.string,
    onDescriptionChange : PropTypes.func,
    dtlReviewerComments : PropTypes.string,
};

export default DNSubmissionForm;
