import ApiBase from "./apiBase";

const apiBase = new ApiBase();

export default function FileApi () {

    const uploadFile = async fileData => {
        if (!fileData)
            return null;
        
        return apiBase.doPost(`api/upload`, fileData)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(`An error occurred uploading the submission file: ${error}`);
            return Promise.reject(error);
        });
    }

    const getFileEncoded = async attachmentDto => {
        if (!attachmentDto) 
            return null;
        
        return apiBase.doPost(`api/submissionfile/encoded`, attachmentDto)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission file contents: ${error}`);
            return Promise.reject(error);
        });
    }

    // TODO: replace this with getFileEncoded
    const getFileContents = async attachmentDto => {
        if (!attachmentDto) 
            return null;
        
        return apiBase.doPost(`api/submissionfile/encoded`, attachmentDto)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission file contents: ${error}`);
            return Promise.reject(error);
        });
    }

    const getFileBinary = async attachmentDto => {
        if (!attachmentDto) 
            return null;
        
        return apiBase.doDownload(`api/submissionfile`, attachmentDto)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission file: ${error}`);
            return Promise.reject(error);
        });
    }

    // TODO: Replace thiw=s with getFileBinary
    const downloadFile = async attachmentDto => {
        if (!attachmentDto) 
            return null;
        
        return apiBase.doDownload(`api/submissionfile`, attachmentDto)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission file: ${error}`);
            return Promise.reject(error);
        });
    }

    return {
        uploadFile : uploadFile,
        getFileEncoded : getFileEncoded,
        getFileContents : getFileContents,
        getFileBinary : getFileBinary,
        downloadFile : downloadFile,
    }

}
