import ApiBase from "./api/apiBase";
const apiBase = new ApiBase();

export default function TranslateService () {
    const getTranslateValues = async (tablename, fieldname) => {
        if (!tablename || !fieldname) {
            Promise.reject("tablename and fieldname are required");
        }
        return apiBase.doGet(`api/translatevalues?tablename=${tablename}&fieldname=${fieldname}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            Promise.reject(`An error occurred retrieving the translate values for ${tablename}.${fieldname}: ${error}`);
        });
    } 

    const getFranchiseList = async () => {
        return apiBase.doGet(`api/franchises/active`)
        .then(response => {
            return response;
        })
        .catch(error => {
            Promise.reject(`An error occurred retrieving the active ffranchise list: ${error}`);
        });
    } 

    return {
        getTranslateValues : getTranslateValues,
        getFranchiseList : getFranchiseList,
    }
}
