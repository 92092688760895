// import React, { Fragment, useEffect, useState } from "react"
import React, { Fragment } from "react"
import PropTypes from "prop-types";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { formatDateMDY } from "../../../utils/Formatters";

const makeTableStyles = makeStyles(() => ({
    tableContainer: {
        height: '110px',
        width: '100%',
    },
    tableHead: {
        padding: '0px 5px',
    },
    tableHeadRow: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailRowEven: {
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    issuerNameCol: {
        maxWidth: '125px',
    },
    jobCodeCol: {
        maxWidth: '95px',
    },
    titleCol: {
        maxWidth: '195px',
    },
    descriptionCol: {
        maxWidth: '195px',
    },
    statusCol: {
        maxWidth: '95px',
    },
    versionCol: {
        maxWidth: '45px',
    },
    submittedCol: {
        maxWidth: '75px',
    },
    ageCol: {
        maxWidth: '60px',
        textAlign: 'right',
    },
}));

const TblRow = (props) => {
    const {
        submissionDetail,
        selectedDetail,
        onClick,
    } = props;

    const tableClasses = makeTableStyles();

    const clickHandler = () => {
        onClick(submissionDetail);
    }

    return (
        <Fragment>
            <TableRow hover={true} selected={selectedDetail && submissionDetail && selectedDetail.issuerArtDtlId === submissionDetail.issuerArtDtlId} onClick={clickHandler}>
                <Tooltip title={`Version ${submissionDetail.version}`}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.versionCol}`}>
                        {submissionDetail.version}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submissionDetail.description || ""}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.descriptionCol}`}>
                        {submissionDetail.description}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submissionDetail.statusDescription || ""}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.statusCol}`}>
                        {submissionDetail.statusDescription || submissionDetail.status}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submissionDetail.uploadDate || "Not uploaded yet"}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.submittedCol}`}>
                        {formatDateMDY(submissionDetail.uploadDate)}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submissionDetail.uploadAge?`${submissionDetail.uploadAge} days`: "Not uploaded yet"}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.ageCol}`}>
                        {submissionDetail.uploadAge}
                    </TableCell>
                </Tooltip>
            </TableRow>
       </Fragment>
    )
}
TblRow.propTypes = {
    submissionDetail : PropTypes.object,
    selectedDetail : PropTypes.object,
    onClick: PropTypes.func,
}

const SubmissionDetailTable = props => {
    const {
        submission,
        selectedDetail,
        onRowSelect,
    } = props;

    const tableClasses = makeTableStyles();

    return (
        <Fragment>
            <TableContainer className={tableClasses.tableContainer}>
                <Table stickyHeader aria-label="Submission Detail Table" size="small">
                    <TableHead className={tableClasses.tableHead}>
                        <TableRow className={tableClasses.tableHeadRow}>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.versionCol}`}>
                                Version
                            </TableCell>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.descriptionCol}`}>
                                Description
                            </TableCell>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.statusCol}`}>
                                Status
                            </TableCell>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.submittedCol}`}>
                                Submitted
                            </TableCell>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.ageCol}`}>
                                Age
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submission && submission.submissionDetails && submission.submissionDetails.map((submissionDetail, index) => {
                            return (<TblRow 
                                index={index} 
                                submissionDetail={submissionDetail} 
                                selectedDetail={selectedDetail}
                                key={submissionDetail.issuerArtDtlId} 
                                onClick={onRowSelect}/>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}
SubmissionDetailTable.propTypes = {
    submission : PropTypes.object,
    selectedDetail : PropTypes.object,
    onRowSelect : PropTypes.func,
}

export default SubmissionDetailTable;
