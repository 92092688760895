import ApiBase from "../../../services/api/apiBase";
const apiBase = new ApiBase()

export default function SubmissionService () {

    const getSubmissionClients = async () => {
        return apiBase.doGet(`api/submission/clients`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(`An error occurred retrieving the active client list: ${error}`);
        });
    }

    const getSubmissionList = async filter => {
        if (!filter)
            return null;
        
        return apiBase.doGet(`api/submissions?filter=${filter}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        })
    }

    const addSubmission = async submission => {
        return apiBase.doPost('api/submission/new', submission)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        });
    }

    const saveSubmission = async submission => {
        if (!submission)
            return null;

        return apiBase.doPut('api/submission', submission)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        });
    }

    // Get a submissionDto object with all of it's children
    const getSubmissionDetails = async submissionId => {
        if (!submissionId)
            return null;
        
        return apiBase.doGet(`api/submissiondetails?submissionid=${submissionId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        })
    }

    const saveSubmissionDetails = async submissionDetails => {
        if (!submissionDetails)
            return null;

        return apiBase.doPut('api/submission/detail', submissionDetails)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        });
    }

    const removeAttachmentById = async issuerArtDtlAttachmentId => {
        if (!issuerArtDtlAttachmentId)
            return null;

        return apiBase.doDelete(`api/submission/detail/attachment/${issuerArtDtlAttachmentId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred deleting the attachment record: ${error}`);
            return Promise.reject(error);
        });
    }

    const cancelSubmission = async submission => {
        if (!submission)
            return null;

        return apiBase.doPost('api/submission/action/cancel', submission)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        });
    }

    const submitSubmission = async submissionDtl => {
        if (!submissionDtl)
            return null;

        return apiBase.doPost('api/submission/action/submit', submissionDtl)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the submission list: ${error}`);
            return Promise.reject(error);
        });
    }

    return {
        getSubmissionClients : getSubmissionClients,
        getSubmissionList : getSubmissionList,
        addSubmission : addSubmission,
        saveSubmission : saveSubmission,
        getSubmissionDetails : getSubmissionDetails,
        saveSubmissionDetails : saveSubmissionDetails,
        removeAttachmentById : removeAttachmentById,
        cancelSubmission : cancelSubmission,
        submitSubmission : submitSubmission,
    }

}
