import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { formatDateMDY } from '../../../utils/Formatters';

const makeTableStyles = makeStyles(() => ({
    tableContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
    },
    tableHead: {
        padding: '0px 5px',
    },
    tableHeadRow: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    versionCol: {
        maxWidth: '45px',
    },
    statusCol: {
        maxWidth: '95px',
    },
    submittedCol: {
        maxWidth: '75px',
    },
    actionCol: {
        maxWidth: '30px',
        width: '30px',
    },
    tableDetailRowEven: {
    },
    iconButton: {
        padding: '0px',
    }
}));

const ReviewItemDtlTable = props => {
    const {
        reviewItem,
        selectedListItem,
        onSelection,
        onShowAttachments,
    } = props;

    const [detailList, setDetailList] = useState(null);

    const classes = makeTableStyles();

    useEffect(() => {
        if (reviewItem && reviewItem.reviewItemDetails && Array.isArray(reviewItem.reviewItemDetails)) {
            reviewItem.reviewItemDetails.sort((i1, i2) => i2.version - i1.version);
            setDetailList(reviewItem.reviewItemDetails);
        } else {
            setDetailList(null);
        }
    }, [reviewItem]);

    useEffect(() => {
        if (detailList == null)
            onRowSelected(null);
        else
            onRowSelected(reviewItem.reviewItemDetails[0]);
    }, [detailList]);

    const onRowSelected = item => {
        onSelection(item);
    }

    const handleShowAttachment = item => {
        onShowAttachments(item);
    }

    return (
        <React.Fragment>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader size="small">
                    <TableHead className={classes.tableHead}>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell className={`${classes.tableHeadCell} ${classes.versionCol}`}>
                                Version
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.statusCol}`}>
                                Status
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.submittedCol}`}>
                                Submitted
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.actionCol}`}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detailList && detailList.map((item, index) => {
                            return (
                                <TableRow hover={true} 
                                    key={`${item.issuerArtDtlId}.${index}`} 
                                    onClick={() => onRowSelected(item)} 
                                    selected={selectedListItem && selectedListItem.issuerArtDtlId == item.issuerArtDtlId}>
                                    <Tooltip title={`Version ${item.version || '0'}`}>
                                        <TableCell className={`${classes.tableDetailCell} ${classes.versionCol}`}>
                                            {item.version}
                                        </TableCell>
                                    </Tooltip>
                                    <Tooltip title={item.statusDesc || ""}>
                                        <TableCell className={`${classes.tableDetailCell} ${classes.statusCol}`}>
                                            {item.statusDescription? item.statusDescription : item.status}
                                        </TableCell>
                                    </Tooltip>
                                    <Tooltip title={item.uploadDate? formatDateMDY(item.uploadDate): "Not uploaded yet"}>
                                        <TableCell className={`${classes.tableDetailCell} ${classes.submittedCol}`}>
                                            {formatDateMDY(item.uploadDate)}
                                        </TableCell>
                                    </Tooltip>
                                    <Tooltip title="Show Attachments">
                                        <TableCell className={`${classes.tableDetailCell} ${classes.actionCol}`}>
                                            <IconButton onClick={() => handleShowAttachment(item)} className={classes.iconButton}>
                                                <OpenInBrowserIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

ReviewItemDtlTable.propTypes = {
    reviewItem : PropTypes.object,
    selectedListItem : PropTypes.object,
    onSelection: PropTypes.func,
    onShowAttachments : PropTypes.func,
};

export default ReviewItemDtlTable;
