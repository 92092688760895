import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";

import Home from './components/Home';
import adsysLogo from './assets/AdsysLogo.png';
import './App.css';

import SubmissionModule from './modules/submitModule/SubmissionModule';
import ReviewModule from './modules/reviewModule/ReviewModule';
import { Container, Link, makeStyles, Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet'
import { authenticationService } from './services/authenticationService';
import PrivateRoute from './components/privateRoute';
import { ConfirmProvider } from "material-ui-confirm";

const makeAppStyles = makeStyles(() => ({
  appContainer: {
    height: '100%',
    padding: '5px',
    maxWidth: '100%',
  },
  pageContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'column',
    height: '100%',
    padding: '0px',
  },
  pageTitle: {
    flex: '0 1 auto',
    width: '100%',
    verticalAlign: 'bottom',
  },
  pageContent: {
    flexGrow: '1',
  },
  pageFooter: {
    flex: '0 1 auto',
  },
  appTitle: {
      display: 'inline-block',
      padding: '0px 5px',
      fontSize: '2em',
      marginBlockStart: '0.1em',
      marginBlockEnd: '0.1em',
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column',
  },
  contentPaper: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'scroll',
    padding: '5px',
    background: '#f7f7f7',
  },
  spacer: {
    flex: '1 1 auto',
  },
  navBar: {
    display: 'flex',
    flexFlow: 'row',
    flex: '0 1 auto',
    padding: '5px',
    background: '#bac4c9',
    borderBottom: '1px solid #999',
    '& a': {
      color: '#0072ff',
      textDecoration: 'none',
      marginLeft: '20px',
      marginRight: '5px',
      },
    '& a:hover':  {
      color: '#8a0f53',
    },
    '& small': {
      color: '#666',
    },
    '& .active': {
      color: '#2c7613',
    },
  },
}));

const validateSession = (invalidSessionHandler) => {
  var interval = setInterval(() => {
    var tokenIsValid = authenticationService.isTokenValid()
    if (!tokenIsValid) {
      console.log("Auth token invalid");
      invalidSessionHandler();
    }
  }, 30000);
  return interval;
}

const App = () => {
  // state variables
  const [token, setToken] = useState(authenticationService.getToken());
  const [currentUser, setCurrentUser] = useState(authenticationService.getCurrentuser);
  
  // Define local variables
  const classes = makeAppStyles();

  useEffect(() => {
    var interval = validateSession(handleLogoutClicked);
    return function cancelInterval() {
      clearInterval(interval);
    }
  }, []);

  // Define local handlers
  const handleLoginComplete = () => {
    setToken(authenticationService.getToken());
    setCurrentUser(authenticationService.getCurrentuser());
  };

  const handleLogoutClicked = () => {
    if (!authenticationService.isLoggedIn())
      return;
      
    authenticationService.logout();
    setToken(null);
    setCurrentUser(null);
  }

  // functional component wrappers
  const NavLinks = (props) => {
    return (
      <Fragment>
        <NavLink exact activeClassName="active" to="/">Home</NavLink>
        {authenticationService.isSubmitter() && <NavLink activeClassName="active" to="/submit">Submit Artwork</NavLink>}
        {(authenticationService.isAdmin() || authenticationService.isReviewer()) && <NavLink activeClassName="active" to="/review">Review Artwork</NavLink>}
        <div className={classes.spacer}/>
        {(authenticationService.getToken() !== null) && <Link href="/DCICardSpecs.pdf" target="_blank">DCI Card Specs</Link>}
        {(authenticationService.getToken() !== null) && <NavLink activeClassName="active" to="/" onClick={props.onLogoutClicked}>Logout</NavLink>}
      </Fragment>
    )
  }
  NavLinks.propTypes = {
    currentuser: PropTypes.object,
    token: PropTypes.string,
    onLogoutClicked: PropTypes.func,
  }

  // return ui
  return (
    <React.Fragment>
      <Container id="App" className={classes.appContainer}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Discover Client Approvals</title>
        </Helmet>
        <BrowserRouter>
          <Container maxWidth="xl" className={classes.pageContainer}>
            <div className="title">
              <img className='appLogo' alt="Ad Systems, Inc. Logo" src={adsysLogo}/>
              <h1 className={classes.appTitle}>AMPs - Discover Client Approvals</h1>
              <small className='version'>v6.1</small>
            </div>
            <div className={classes.navBar}>
              <NavLinks token={token} currentuser={currentUser} onLogoutClicked={handleLogoutClicked} />
            </div>
            <div className={classes.contentContainer}>
              <Paper className={classes.contentPaper}>
                  <ConfirmProvider>
                    <Switch>
                      <Route exact path="/" >
                        <Home onLoginComplete={handleLoginComplete} />
                      </Route>
                      <PrivateRoute path="/submit" >
                        <SubmissionModule />
                      </PrivateRoute>
                      <PrivateRoute path="/review" >
                        <ReviewModule />
                      </PrivateRoute>
                      <Route path="*" >
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </ConfirmProvider>
              </Paper>
            </div>
            <div id="footer" className={classes.pageFooter}>
              <small>
                Copyright © 2020 Ad Systems, Inc. All Rights Reserved
              </small>
            </div>
          </Container>
        </BrowserRouter>
      </Container>
    </React.Fragment>
  );
}
 
export default App;