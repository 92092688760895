import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { Route, useHistory } from 'react-router-dom';
// import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../services/authenticationService';

const PrivateRoute = ({children, ...rest}) => {
  const path = rest.path;

  const history = useHistory();

  useEffect(() => {
    if (!authenticationService.getToken()) {
      history.push("/login");
    } else {
      if (!canLoad()) {
        history.push("/");
      }
    }
  });

  const canLoad = () => {
    return (
      (path == "/review" && (authenticationService.isReviewer() || authenticationService.isAdmin())) 
      || (path == "/reports" && (authenticationService.isReports() || authenticationService.isAdmin())) 
      || (path == "/admin" && authenticationService.isAdmin()) 
      || (path == "/submit" && authenticationService.isSubmitter()) 
      || (path == "/")
    );
  }

  return (
    <Route {...rest}
      render={() => {
        return (
          canLoad() ? children : <span>No Access!</span>
          )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PrivateRoute;
