import React, { useState } from 'react';
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { IconButton, makeStyles } from "@material-ui/core"
import RateReviewIcon from '@material-ui/icons/RateReview';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { formatDateMDY } from '../../../utils/Formatters';

const makeGridStyles = makeStyles(() => ({
    iconButton: {
        padding: '0px',
    },
}))

const ReviewButtonRenderer = props => {
    const {
        data,
        onClick,
    } = props;

    const classes = makeGridStyles();

    const handleClick = () => {
        onClick(data);
    }

    return (
        <IconButton onClick={() => handleClick()} className={classes.iconButton}>
            <RateReviewIcon/>
        </IconButton>
    )
}
ReviewButtonRenderer.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

const ReviewDatagrid = (props) => {
    const {
        reviewItems,
        onSelection,
        onShowReview,
    } = props;

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = params => {
        setGridApi(params.api);
    }

    const onSelectionChanged = () => {
        var selectedRows = gridApi.getSelectedRows();
        if (selectedRows.length === 1)
            onSelection(selectedRows[0]);
    }

    const dateFormatter = params => {
        return formatDateMDY(params.value);
    }

    const dateTooltip = parms => {
        return formatDateMDY(parms.value);
    }

    const versionTooltip = parms => {
        return 'Version ' + parms.value;
    }

    const handleShowReview = item => {
        onShowReview(item);
    }

    const reviewButtonRendererParams = {
        onClick: handleShowReview,
    }
    const frameworkComponents = {
        reviewButtonRenderer : ReviewButtonRenderer,
    }

    const dateFilterParams = {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        },
        browserDatePicker: true,
    }

    return (
        <React.Fragment>
            <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={reviewItems}
                    frameworkComponents={frameworkComponents}
                    rowSelection={'single'}
                    onSelectionChanged={onSelectionChanged}
                    rowHeight={35}>
                    <AgGridColumn field="issuerName" headerName="Issuer Name" type="string" 
                        tooltipField="issuerName" flex={2} sortable={true} filter={true}></AgGridColumn>
                    <AgGridColumn field="jobCode" headerName="Job Number" type="string" 
                        tooltipField="jobCode" flex={1} sortable={true} filter={true}></AgGridColumn>
                    <AgGridColumn field="filename" headerName="File Name" type="string" 
                        tooltipField="filename" flex={2} sortable={true} filter={true}></AgGridColumn>
                    <AgGridColumn field="description" headerName="Description" type="string" 
                        tooltipField="description" flex={2} sortable={true} filter={true}></AgGridColumn>
                    <AgGridColumn field="statusDesc" headerName="Status" type="string" 
                        tooltipField="statusDesc" flex={1} sortable={true} filter={true}></AgGridColumn>
                    <AgGridColumn field="currentVersion" headerName="Version" type="numericColumn" 
                        tooltipValueGetter={versionTooltip} resizable={false} width="110" sortable={true} filter="agNumberColumnFilter"></AgGridColumn>
                    <AgGridColumn field="submitDate" headerName="Submitted" 
                        tooltipValueGetter={dateTooltip} resizable={false} sortable={true} width="120" 
                        filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={dateFormatter}></AgGridColumn>
                    <AgGridColumn field="submitAge" headerName="Age" type="numericColumn" 
                        tooltipField="submitAge" resizable={false} width="80" sortable={true} filter="agNumberColumnFilter"/>
                    <AgGridColumn width="50" cellRenderer="reviewButtonRenderer" cellRendererParams={reviewButtonRendererParams}/>
                </AgGridReact>
            </div>
        </React.Fragment>
    )
};
ReviewDatagrid.propTypes = {
    reviewItems: PropTypes.array,
    selectedListItem : PropTypes.object,
    onSelection: PropTypes.func,
    onShowReview: PropTypes.func,
};

export default ReviewDatagrid;