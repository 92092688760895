import React from 'react';
// import React, { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core"
import PropTypes from "prop-types";
import { formatDateMDY } from '../../../utils/Formatters';



const makeTableStyles = makeStyles(() => ({
    tableContainer: {
        height: '100%',
        width: '100%',
    },
    tableHead: {
        padding: '0px 5px',
    },
    tableHeadRow: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailRowEven: {
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    issuerNameCol: {
        maxWidth: '125px',
    },
    jobCodeCol: {
        maxWidth: '95px',
    },
    titleCol: {
        maxWidth: '195px',
    },
    descriptionCol: {
        maxWidth: '195px',
    },
    statusCol: {
        maxWidth: '95px',
    },
    versionCol: {
        maxWidth: '45px',
    },
    submittedCol: {
        maxWidth: '75px',
    },
    ageCol: {
        maxWidth: '60px',
        textAlign: 'right',
    },
}));

const TblRow = (props) => {
    const {
        submission,
        selectedItem,
        onClick,
    } = props;
    const classes = makeTableStyles();

    const clickHandler = () => {
        onClick(submission);
    }

    return (
        <React.Fragment>
            <TableRow hover={true} onClick={clickHandler} selected={selectedItem && submission && submission.issuerArtId == selectedItem.issuerArtId}>
                <Tooltip title={submission.issuerName || 'No Issuer selected'}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.issuerNameCol}`}>
                        {submission.issuerName}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.jobCode || ''}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.jobCodeCol}`}>
                        {submission.jobCode}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.filename? submission.filename: "No filename provided"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.titleCol}`}>
                        {submission.filename}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.description || ""}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.descriptionCol}`}>
                        {submission.description}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.statusDesc || ""}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.statusCol}`}>
                        {submission.statusDesc? submission.statusDesc : submission.status}
                    </TableCell>
                </Tooltip>
                <Tooltip title={`Version ${submission.currentVersion || '0'}`}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.versionCol}`}>
                        {submission.currentVersion}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.uploadDate || "Not uploaded yet"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.submittedCol}`}>
                        {formatDateMDY(submission.uploadDate)}
                    </TableCell>
                </Tooltip>
                <Tooltip title={submission.uploadAge? `${submission.uploadAge} days`: "Not uploaded yet"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.ageCol}`}>
                        {submission.uploadAge}
                    </TableCell>
                </Tooltip>
            </TableRow>
        </React.Fragment>
    )
}

TblRow.propTypes = {
    submission: PropTypes.object,
    selectedItem : PropTypes.object,
    onClick: PropTypes.func,
};

const SubmissionTable = (props) => {
    const {
        submissionItems,
        selectedListItem,
        loading,
        onSelection,
    } = props;

    const classes = makeTableStyles();

    const rowClickHandler = (submission) => {
        onSelection(submission);
    }
    
    return (
        <React.Fragment>
            <TableContainer className={classes.tableContainer} size="small">
                <Table stickyHeader aria-label="Submission Table" >
                    <caption>
                        {loading? "Searching..." : 
                        `${submissionItems && Array.isArray(submissionItems) && submissionItems.length > 0? submissionItems.length: "No"} submissions`
                        }
                    </caption>
                    <TableHead className={classes.tableHead}>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell className={`${classes.tableHeadCell} ${classes.issuerNameCol}`}>
                                Issuer Name
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.jobCodeCol}`}>
                                Job Number
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.titleCol}`}>
                                File Name
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.descriptionCol}`}>
                                Description
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.statusCol}`}>
                                Status
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.versionCol}`}>
                                Version
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.submittedCol}`}>
                                Submitted
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.ageCol}`}>
                                Age
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submissionItems && submissionItems.map((submission, index) => {
                            return (<TblRow index={index} 
                                submission={submission} 
                                selectedItem={selectedListItem}
                                key={submission.issuerArtId} 
                                onClick={rowClickHandler}/>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
};

SubmissionTable.propTypes = {
    submissionItems: PropTypes.array,
    selectedListItem : PropTypes.object,
    loading: PropTypes.bool,
    onSelection: PropTypes.func,
};

export default SubmissionTable;