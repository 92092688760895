import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';

const makeFilterStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
    },
    filterLegend: {
        flex: '0 1 auto',
        paddingRight: '10px',
    },
    filterOption: {
        flex: '0 1 auto',
    },
}));

/**
 * Changes to the filter options have to be mirrored in the apiSubmissionService.cfc.
 */
export const FilterOptions = {
    ATTN: {value: "attn", label: "Requiring Attention",},
    IP: {value: "ip", label: "In Process",},
    DONE: {value: "done", label: "Completed",},
    ALL: {value: "all", label: "All",},
}; 
    
const SubmissionFilter = ({
    onFilterChange,
    onAddRequest,
}) => {
    const [currentFilter, setCurrentFilter] = useState(FilterOptions.ATTN.value);

    const classes = makeFilterStyles();

    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    }

    useEffect(() => {
        onFilterChange(currentFilter);
    }, [currentFilter]);

    return (
        <Grid container direction="row" alignItems="baseline">
            <Grid item xs={9}>
                <FormControl className={classes.container} size="small">
                    <FormLabel className={classes.filterLegend} >Show Which Items:</FormLabel>
                    <RadioGroup row name="filterGroup" value={currentFilter} onChange={handleFilterChange}>
                        <FormControlLabel 
                            className={classes.filterOption} 
                            value={FilterOptions.ATTN.value}
                            control={<Radio size="small" />} 
                            label={FilterOptions.ATTN.label}/>
                        <FormControlLabel 
                            className={classes.filterOption} 
                            value={FilterOptions.IP.value}
                            control={<Radio size="small" />} 
                            label={FilterOptions.IP.label}/>
                        <FormControlLabel 
                            className={classes.filterOption} 
                            value={FilterOptions.DONE.value}
                            control={<Radio size="small" />} 
                            label={FilterOptions.DONE.label}/>
                        <FormControlLabel 
                            className={classes.filterOption} 
                            value={FilterOptions.ALL.value}
                            control={<Radio size="small" />} 
                            label={FilterOptions.ALL.label}/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={3} container direction="row" justify="flex-end">
                <Button onClick={onAddRequest} size="small" variant="contained">New Request</Button>
            </Grid>
        </Grid>
    );
};

SubmissionFilter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    onAddRequest: PropTypes.func,
};

export default SubmissionFilter;

