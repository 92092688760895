import moment from "moment";

export const formatDateMDY = date => {
    if (!date) return null;
    return date ? moment(new Date(date)).format('MM/DD/YYYY') : '';
}

export const formatDateYMD = date => {
    if (!date) return null;
    return date ? moment(new Date(date)).format('YYYY/MM/DD') : '';
}
