import axios from "axios";
import { setSessionToken } from "../../utils/storageAccess";
import { authenticationService } from "../authenticationService";
const apiServer = process.env.REACT_APP_API_SERVER;

export default function ApiBase() {
    const commonHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    
    const doAxios = async (config) => {
    
        axios.interceptors.response.use(undefined, error => {
            if (!error) {
                console.log(`An unknown error was encountered access the api: ${config}`);
                return Promise.reject(`An unknown error was encountered access the api: ${config}`);
            }
            
            if (error.message === 'Network Error' && !error.response) {
                console.log(`Network error - cannot reach api server`);
                return Promise.reject(`Network error - cannot reach api server`);
            }

            if (!error.response) {
                console.log(`Unknown response from api request: ${error}`);
                return Promise.reject(`Unknown response from api request: ${error}`);
            }
        
            const {status, data, config} = error.response;
        
            if (status === 404) {
                console.log('Not Found');
                return Promise.reject(`Not Found`);
            }
        
            if (status === 400 && config.method === 'get' && data.errors && data.errors.id) {
                console.log(`http status 400 not found`);
                return Promise.reject(`http status 400 not found`);
            }
        
            if (status === 500) {
                console.log(`Server error - check the server logs for more info`);
                return Promise.reject(`Server error - check the server logs for more info`);
            }
        
            if (status === 401) {
                // token has expired
                // Request a refresh token, then try the request again
                console.log('Unauthorized status returned from api server.');
                authenticationService.logout();
            }
        
            if (status === 403) {
                console.log('Forbidden status returned from api server.');
                authenticationService.logout();
            }
        })
    
        return axios(config)
        .then(response => {
            if (!response) {
                console.log("No response returned from api call");
                return Promise.reject("No response returned from api call");
            }
            if (!response.status) {
                console.log("No response status returned from api call");
                return Promise.reject("No response status returned from api call");
            }
            if (response.status == 200) {
                if (response.headers['refresh-token']) {
                    const refreshToken = response.headers['refresh-token'];
                    setSessionToken(refreshToken);
                }

                return response.data;
            } else {
                if (response.status == 204) {
                    // No content
                    return "";
                }
                if ([401,403].indexOf(response.status) !== -1) {
                    // auto logout if 401 (Unauthorized) or 403 (Forbidden)
                    authenticationService.logout();
                    console.log(`The session is not valid. http request returned ${response.status}`);
                    return Promise.reject("Session is not valid");
                }
                console.log(`${config.method} returned unexpected status: ${response.status}; ${response.statusText}`);
                const errorMsg = (response.data && response.data.message) || response.statusText;
                return Promise.reject(errorMsg);
            }
        })
        .catch(error => {
            console.log(`An error occured in the ${config.method}: ${error}`);
            return Promise.reject(error);
        });
    }
    
    const doPost = async (path, data, headers) => {
        const url = `${apiServer}/${path}`;
    
        let reqHeaders = Object.assign(commonHeaders);
    
        if (headers)
            reqHeaders = Object.assign(reqHeaders, headers);
    
        if (authenticationService.getToken())
            reqHeaders = Object.assign(reqHeaders, authenticationService.getAuthHeader());
    
        const config = {
            method: 'post',
            mode: 'no-cors',
            url: url,
            data: data,
            headers: reqHeaders,
        }
    
        return doAxios(config);
    }
    
    const doPut = async (path, data, headers) => {
        const url = `${apiServer}/${path}`;
    
        let reqHeaders = Object.assign(commonHeaders);
    
        if (headers)
            reqHeaders = Object.assign(reqHeaders, headers);
    
        if (authenticationService.getToken())
            reqHeaders = Object.assign(reqHeaders, authenticationService.getAuthHeader());
    
        const config = {
            method: 'put',
            mode: 'no-cors',
            url: url,
            data: data,
            headers: reqHeaders,
        }
    
        return doAxios(config);
    }
    
    const doGet = async (path, headers) => {
        const url = `${apiServer}/${path}`;
    
        let reqHeaders = Object.assign({}, commonHeaders);
    
        if (headers)
            reqHeaders = Object.assign(reqHeaders, headers);
    
        const token = authenticationService.getToken();
        if (token) {
            let authHdr = authenticationService.getAuthHeader();
            reqHeaders = Object.assign(reqHeaders, authHdr);
        }
    
        const config = {
            method: 'get',
            mode: 'no-cors',
            url: url,
            headers: reqHeaders,
        }
        return doAxios(config);
    }
    
    const doDownload = async (path, data, headers) => {
        const url = `${apiServer}/${path}`;
    
        let reqHeaders = Object.assign(commonHeaders);
    
        if (headers)
            reqHeaders = Object.assign(reqHeaders, headers);
    
        if (authenticationService.getToken())
            reqHeaders = Object.assign(reqHeaders, authenticationService.getAuthHeader());
    
        const config = {
            method: 'post',
            mode: 'no-cors',
            url: url,
            responseType : 'blob',
            data: data,
            headers: reqHeaders,
        }
    
        return doAxios(config);
    }
    
    const doDelete = async (path, headers) => {
        const url = `${apiServer}/${path}`;
    
        let reqHeaders = Object.assign(commonHeaders);
    
        if (headers)
            reqHeaders = Object.assign(reqHeaders, headers);
    
        if (authenticationService.getToken())
            reqHeaders = Object.assign(reqHeaders, authenticationService.getAuthHeader());
    
        const config = {
            method: 'delete',
            mode: 'no-cors',
            url: url,
            headers: reqHeaders,
        }
    
        return doAxios(config);
    }

    return {
        doGet : doGet,
        doPost : doPost,
        doPut : doPut,
        doDownload : doDownload,
        doDelete : doDelete,
    }
}
    
