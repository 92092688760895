import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@material-ui/core";

const makeTableStyles = makeStyles(() => ({
    tableContainer: {
        height: '100%',
        width: '100%',
    },
    tableHead: {
        padding: '0px 5px',
    },
    tableHeadRow: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailRowEven: {
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    filenameCol: {
        width: '30%',
    },
    commentsCol: {
    },
}));

const TblRow = (props) => {
    const {
        selectedRow,
        rowItem,
        onClick,
        onAttachmentChanged,
    } = props;

    const tableClasses = makeTableStyles();

    const [comments, setComments] = useState('');

    useEffect(() => {
        if (rowItem && !!rowItem.comments)
            setComments(rowItem.comments)
        else
            setComments('');
    }, [rowItem]);

    const clickHandler = () => {
        onClick(rowItem);
    }

    const onCommentsChanged = event => {
        setComments(event.target.value);
    }

    const onCommentsBlur = () => {
        var updatedRow = Object.assign(rowItem);
        updatedRow.comments = comments;
        onAttachmentChanged(updatedRow);
    }

    return (
        <Fragment>
            <TableRow hover={true} selected={selectedRow && rowItem && selectedRow.issuerArtDtlAttachmentId === rowItem.issuerArtDtlAttachmentId} onClick={clickHandler}>
                <Tooltip title={rowItem.filename}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.filenameCol}`}>
                        {rowItem.filename}
                    </TableCell>
                </Tooltip>
                <Tooltip title={comments || "No comments provided"}>
                    <TableCell className={`${tableClasses.tableDetailCell} ${tableClasses.commentsCol}`}>
                        {!rowItem.isEditable && `${comments}`}
                        {rowItem.isEditable && <TextField size="small" name="comments" fullWidth placeholder="Comments" value={comments} onChange={onCommentsChanged} onBlur={onCommentsBlur}/>}
                    </TableCell>
                </Tooltip>
            </TableRow>
       </Fragment>
    )
}
TblRow.propTypes = {
    selectedRow : PropTypes.object,
    rowItem : PropTypes.object,
    onClick: PropTypes.func,
    onAttachmentChanged : PropTypes.func,
}

const AttachmentTable = props => {
    const {
        attachmentsList,
        selectedAttachment,
        onRowSelect,
        onAttachmentChanged,
    } = props;

    const tableClasses = makeTableStyles();

    return (
        <Fragment>
            <TableContainer className={tableClasses.tableContainer}>
                <Table stickyHeader size="small" aria-label="Attachment Table" >
                    <TableHead className={tableClasses.tableHead}>
                        <TableRow className={tableClasses.tableHeadRow}>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.filenameCol}`}>
                                Filename
                            </TableCell>
                            <TableCell className={`${tableClasses.tableHeadCell} ${tableClasses.commentsCol}`}>
                                Comments
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attachmentsList && 
                            attachmentsList
                                .map((attachment, index) => {
                                    return (<TblRow 
                                        index={index} 
                                        rowItem={attachment} 
                                        selectedRow={selectedAttachment}
                                        key={attachment.issuerArtDtlAttachmentId} 
                                        onClick={onRowSelect}
                                        onAttachmentChanged={onAttachmentChanged}/>)
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}
AttachmentTable.propTypes = {
    attachmentsList : PropTypes.array,
    selectedAttachment : PropTypes.object,
    onRowSelect : PropTypes.func,
    onAttachmentChanged : PropTypes.func,
}

export default AttachmentTable;
