import React, { Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const makeCommentStyles = makeStyles(() => ({
    commentStyle: {
        paddingBottom: '10px;',
    },
    commentVersionStyle: {
        fontWeight: 'bold',
        paddingLeft: '10px;',
    },
    commentPersonStyle: {
        fontWeight: 'bold',
        paddingLeft: '20px;',
    },
    commentCommentStyle: {
        paddingLeft: '30px;',
    },
    commentEmptyStyle: {
        paddingLeft: '30px;',
        fontStyle: 'italic',
    },
}))

const DetailComments = props => {
    const {
        reviewDetail,
        commentsToggle,
    } = props;

    const [approvals, setApprovals] = useState(null);

    const classes = makeCommentStyles();

    useEffect(() => {
        if (reviewDetail && reviewDetail.detailApprovals && Array.isArray(reviewDetail.detailApprovals)) {
            setApprovals(reviewDetail.detailApprovals);
        } else {
            setApprovals(null)
        }
    }, [reviewDetail]);

    return (
        <Fragment>
            {approvals && approvals.map((approval, index) => {
                return (
                    <Grid item xs={12} key={`${approval.issuerArtDtlApprovalId}.${index}`} className={classes.commentStyle}>
                        <Typography className={classes.commentPersonStyle}>{approval.personName} ({approval.statusDesc}):</Typography>
                        {commentsToggle == "E" && !!approval.extComments && <Typography className={classes.commentCommentStyle}>{approval.extComments}</Typography>}
                        {commentsToggle == "E" && !approval.extComments && <Typography className={classes.commentEmptyStyle}>No comments to client entered</Typography>}
                        {commentsToggle == "I" && !!approval.intComments && <Typography className={classes.commentCommentStyle}>{approval.intComments}</Typography>}
                        {commentsToggle == "I" && !approval.intComments && <Typography className={classes.commentEmptyStyle}>No internal comments entered</Typography>}
                    </Grid>
                )
            })}
        </Fragment>
    )
}
DetailComments.propTypes = {
    reviewDetail : PropTypes.object,
    commentsToggle : PropTypes.string,
};


const ReviewCommentsViewer = props => {
    const {
        reviewItem,
        commentsToggle,
    } = props;
    
    const [reviewDetails, setReviewDetails] = useState(null);

    const classes = makeCommentStyles();

    useEffect(() => {
        if (reviewItem && reviewItem.reviewItemDetails && Array.isArray(reviewItem.reviewItemDetails)) {
            setReviewDetails(reviewItem.reviewItemDetails);
        } else {
            setReviewDetails(null)
        }
    }, [reviewItem]);

    return (
        <Fragment>
            {reviewDetails && reviewDetails.map((reviewDetail, index) => {
                return (
                    <Grid item xs={12} key={`${reviewDetail.issuerArtDtlId}.${index}`} className={classes.commentStyle}>
                        <Typography className={classes.commentVersionStyle}>Version {reviewDetail.version}:</Typography>
                        <DetailComments reviewDetail={reviewDetail} commentsToggle={commentsToggle}/>
                    </Grid>
                )
            })}
        </Fragment>
    )
}
ReviewCommentsViewer.propTypes = {
    reviewItem : PropTypes.object,
    commentsToggle : PropTypes.string,
};

export default ReviewCommentsViewer;
