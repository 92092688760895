import ApiBase from "../../../services/api/apiBase";
const apiBase = new ApiBase()

export default function ReviewService () {

    const getReviewClients = async () => {
        return apiBase.doGet(`api/review/clients`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(`An error occurred retrieving the active client list: ${error}`);
        });
    }

    const getReviewList = async filter => {
        if (!filter)
            return null;
        
        return apiBase.doGet(`api/reviews?filter=${filter}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            return Promise.reject(error);
        })
    }

    // Get a reviewDto object with all of it's children
    const getReviewItem = async reviewId => {
        if (!reviewId)
            return null;
        
        return apiBase.doGet(`api/reviewdetails?issuerArtId=${reviewId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review item: ${error}`);
            return Promise.reject(error);
        })
    }

    const saveReview = async review => {
        if (!review)
            return null;

        return apiBase.doPut('api/review', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            return Promise.reject(error);
        });
    }

    const removeAttachmentById = async issuerArtDtlAttachmentId => {
        if (!issuerArtDtlAttachmentId)
            return null;

        return apiBase.doDelete(`api/submission/detail/attachment/${issuerArtDtlAttachmentId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred deleting the attachment record: ${error}`);
            return Promise.reject(error);
        });
    }

    // Get a reviewDto object with all of it's children
    const getCompliancerules = async reviewDetailId => {
        if (!reviewDetailId)
            return null;
        
        return apiBase.doGet(`api/compliancerules?issuerArtDtlId=${reviewDetailId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the compliance rules: ${error}`);
            return Promise.reject(error);
        })
    }

    // Get a reviewDto object with all of it's children
    const getDetailApprovals = async reviewDetailId => {
        if (!reviewDetailId)
            return null;
        
        return apiBase.doGet(`api/review/approvals?issuerArtDtlId=${reviewDetailId}`)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the approvals: ${error}`);
            return Promise.reject(error);
        })
    }

    const pendReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/pend', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred 'PEND'ing the review: ${error}`);
            return Promise.reject(error);
        });
    }

    const approveReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/approve', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred 'approve'ing the review: ${error}`);
            return Promise.reject(error);
        });
    }

    const returnReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/return', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred 'return'ing the review: ${error}`);
            return Promise.reject(error);
        });
    }

    const finalApproveReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/finalapprove', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred 'finalapprove'ing the review: ${error}`);
            return Promise.reject(error);
        });
    }

    const finalReturnReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/finalreturn', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred 'finalreturn'ing the review: ${error}`);
            return Promise.reject(error);
        });
    }

    



    const saveReviewDetails = async reviewDetails => {
        if (!reviewDetails)
            return null;

        return apiBase.doPut('api/review/detail', reviewDetails)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            return Promise.reject(error);
        });
    }

    const cancelReview = async review => {
        if (!review)
            return null;

        return apiBase.doPost('api/review/action/cancel', review)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            return Promise.reject(error);
        });
    }

    const submitReview = async reviewDtl => {
        if (!reviewDtl)
            return null;

        return apiBase.doPost('api/review/action/submit', reviewDtl)
        .then(result => {
            return result;
        })
        .catch(error => {
            console.log(`An error occurred retrieving the review list: ${error}`);
            return Promise.reject(error);
        });
    }

    return {
        getReviewClients : getReviewClients,
        getReviewList : getReviewList,
        getReviewItem : getReviewItem,
        getCompliancerules : getCompliancerules,
        getDetailApprovals : getDetailApprovals,
        pendReview : pendReview,
        approveReview : approveReview,
        returnReview : returnReview,
        finalApproveReview : finalApproveReview,
        finalReturnReview : finalReturnReview,



        saveReview : saveReview,
        saveReviewDetails : saveReviewDetails,
        removeAttachmentById : removeAttachmentById,
        cancelReview : cancelReview,
        submitReview : submitReview,
    }

}
