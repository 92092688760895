import jwtDecode from "jwt-decode";
import { clearSessionToken, getSessionToken, setSessionToken } from "../utils/storageAccess";
import ApiAuthentication from "./api/apiAuthentication";
const apiAuthentication = new ApiAuthentication();

const Roles = {
    SUBMISSIONS: "Submissions only",
    ISSUER_SUBMISSIONS: "Issuer Submissions",
    APPROVALS: "Approvals only",
    APPROVALS_REPORTS: "Approvals and Reports",
    ADMIN: "Administrator",
}

const getToken = () => {
    return getSessionToken();
}

const getCurrentuser = () => {
    var token = getToken();
    if (!token)
        return null;

    var tokenDetails = jwtDecode(token);
    return tokenDetails.user;
}

const getCurrentUserRole = () => {
    var token = getToken();
    if (!token)
        return null;
    var tokenDetails = jwtDecode(token);
    return tokenDetails.role;
}

const isTokenValid = () => {
    var token = getToken();
    if (!token)
        return false;

    var expires = jwtDecode(token).exp;
    return (Date.now() < (expires * 1000));
}

const isLoggedIn = () => {
    var token = getToken();
    return !!token;
}

export const authenticationService = {

    Roles: Roles,

    login : async (username, password) => {
        return apiAuthentication.userLogin(username, password)
        .then(response => {
            if (response)
                setSessionToken(response);
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
    },

    logout : () => {
        clearSessionToken();
        location.reload();
    },

    getToken : getToken,
    
    getCurrentuser : getCurrentuser,
    
    getCurrentUserRole : getCurrentUserRole,

    isTokenValid : isTokenValid,

    isLoggedIn : isLoggedIn,

    getAuthHeader : () => {
        var token = getToken();
        if (token)
            return {
                Authorization: `Bearer ${token}`,
            }
        else
            return {}
    },
    
    isSubmitter : () => {
        return (getCurrentUserRole() === Roles.SUBMISSIONS || getCurrentUserRole() === Roles.ISSUER_SUBMISSIONS);
    },
    
    isReviewer : () => {
        return (getCurrentUserRole() === Roles.APPROVALS || getCurrentUserRole() === Roles.APPROVALS_REPORTS || getCurrentUserRole() === Roles.ADMIN);
    },
    
    isAdmin : () => {
        return getCurrentUserRole() === Roles.ADMIN;
    },
    
    isReports : () => {
        return (getCurrentUserRole() === Roles.APPROVALS_REPORTS || getCurrentUserRole() === Roles.ADMIN);
    }

}

export default authenticationService;
