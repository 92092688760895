import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
        />
    );
}
NumberFormatCustom.propTypes = {
    name : PropTypes.string.isRequired,
    inputRef : PropTypes.func.isRequired,
    onChange : PropTypes.func.isRequired,
}

const DCISubmissionForm = props => {
    const {
        // submission,
        selectedDetail,
        pickLists,
        franchise,
        onFranchiseChange,
        mfrJobNum,
        onMfrJobNumChange,
        qty,
        onQtyChange,
        cardType,
        onCardTypeChange,
        dtlDescription,
        onDescriptionChange,
    } = props;

    const isFranchiseSelected = (option, value) => {
        return option.franchiseId == value.franchiseId;
    }
    const renderFranchiseSelectInput = (params) => {
        return (
            <TextField {...params} label="Franchise" variant="outlined" />
        )
    }
    const findFranchiseLabel = option => {
        return (option && option.franchiseId? option.franchiseName: "No Franchise selected");
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Autocomplete
                    name="franchiseId"
                    fullWidth
                    id="franchiseCB" 
                    size="small"
                    required={true}
                    loading={!pickLists || !pickLists.franchiseList}
                    options={pickLists.franchiseList}
                    disabled={!(selectedDetail && selectedDetail.isEditable)}
                    value={franchise}
                    selectOnFocus={true}
                    autoSelect={true}
                    disableClearable={true}
                    getOptionSelected={isFranchiseSelected}
                    getOptionLabel={findFranchiseLabel}
                    renderInput={renderFranchiseSelectInput}
                    onChange={onFranchiseChange}
                />
            </Grid>
            <Grid item xs={12}
                container direction="row" spacing={1}>
                <Grid item xs={9}>
                    <TextField 
                        name="mfrJobNum"
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        required={true}
                        label="Manufacturer Job" 
                        autoComplete="new-password"
                        margin="dense"
                        disabled={!(selectedDetail && selectedDetail.isEditable)}
                        value={mfrJobNum || ''}
                        onChange={onMfrJobNumChange}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="qtyNum"
                        fullWidth
                        variant="outlined"
                        size="small"
                        required={true}
                        label="Quantity"
                        autoComplete="new-password"
                        margin="dense"
                        disabled={!(selectedDetail && selectedDetail.isEditable)}
                        value={qty || null}
                        onChange={onQtyChange}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <FormControl component="fieldset" variant="outlined" required={true}>
                    <FormLabel>Card Type</FormLabel>
                    <RadioGroup 
                        row={true}
                        value={cardType}
                        onChange={onCardTypeChange}>
                        <FormControlLabel value="H" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Horizontal"/>
                        <FormControlLabel value="V" control={<Radio size="small" disabled={!(selectedDetail && selectedDetail.isEditable)}/>} label="Vertical"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled={!selectedDetail}
                    name="description"
                    label="Description"
                    size="small" 
                    fullWidth
                    multiline
                    rows={3}
                    value={dtlDescription || ""}
                    variant="outlined"
                    onChange={onDescriptionChange}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Reviewer Comments"
                    size="small" 
                    fullWidth
                    multiline
                    rows={3}
                    value={selectedDetail.dtlReviewerComments || ""}
                    variant="outlined"
                    disabled={true}/>
            </Grid>
        </Fragment>
    )
}

DCISubmissionForm.propTypes = {
    submission : PropTypes.object,
    selectedDetail : PropTypes.object,
    pickLists : PropTypes.object,
    franchise : PropTypes.object,
    onFranchiseChange : PropTypes.func,
    mfrJobNum : PropTypes.string,
    onMfrJobNumChange : PropTypes.func,
    qty : PropTypes.string,
    onQtyChange : PropTypes.func,
    cardType : PropTypes.string,
    onCardTypeChange : PropTypes.func,
    dtlDescription : PropTypes.string,
    onDescriptionChange : PropTypes.func,
};

export default DCISubmissionForm;
